body {
    background-color: #232e31 !important;
}

.headerPadding {
   padding: 0 5px;
}

.headerMargin {
    margin: 0 5px;
}

.externalLinks {
    text-align: center;
}

.contactInfo {
    margin-top: 10px;
}

.buttonLinks {
    color: #9ba5a6;
    font-family: 'Poppins';
    background-color: #232e31;
    margin: 0 10px 10px 10px;
}

.buttonLinks:hover {
    color: #9ba5a6;
    font-family: 'Poppins';
    background-color: #314145;
    margin: 0 10px 10px 10px;
}

.buttonLinks:active:hover {
    color: #9ba5a6;
    font-family: 'Poppins';
    background-color: #314145;
    margin: 0 10px 10px 10px;
}

.cardCol {
    margin-bottom: 30px;
}

a {
    color: #9ba5a6;
    text-decoration: none;
}

a:hover {
    color: #9ba5a6;
    text-decoration: underline;
}

.cvLink {
    font-size: 16pt;
    font-weight: 500;
    padding-left: 5px;
}

.navbarLinksContainer {
    display: flex;
    align-items: center;
}

.projectImage {
    border: 1px solid black;
    height: auto;
    width: 100%;
}

@media (max-width: 768px) {

    .introContainer {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .projectContainer {
        padding-top: 0 !important;
    }

    .nameText {
        font-size: 32pt !important;
    }

    .descText {
        font-size: 13pt !important;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .projectTitle {
        font-size: 16pt !important;
    }

    .projectDesc {
        font-size: 13pt !important;
    }

    .footerContainer {
        padding-top: 0 !important;
    }
}

/*style updates for very small phones (iPhone SE, etc.)*/
@media (max-width: 470px) {
    .navbarTitle {
        font-size: 11pt !important;
    }   
    
    .cvLink {
        font-size: 11pt;
    }
}